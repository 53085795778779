import { React } from "react";

import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  Link,
  color,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';
const SupportedSites = () => {
  const myArray = [
  {
    id: 1,
    title: 'The Economist',
    text: 'https://www.shacklefree.in/https://www.economist.com/the-world-in-brief',
    state: 'Could be better'
  },
  {
    id: 2,
    title: 'Medium',
    text: 'https://www.shacklefree.in/https://medium.com/in-fitness-and-in-health/looking-better-than-99-of-people-over-40-is-about-1-thing-ddcd39936cf7',
    state: 'Works like a charm'
  },
  {
    id: 3,
    title: 'BQ Prime',
    text: 'https://www.shacklefree.in/https://www.bqprime.com/research-reports/nestle-india-q1-results-review-volume-neutralises-raw-material-price-inflation-prabhudas-lilladher?cx_testId=4&cx_testVariant=cx_1&cx_artPos=1&cx_experienceId=EXE5JL767CKY#cxrecs_s',
    state: 'Works like a charm'
  },
  {
    id: 4,
    title: 'Financial Times',
    text: 'https://www.shacklefree.in/https://www.ft.com/content/3a84b28c-912a-4805-b942-13e597766a93',
    state: 'Works like a charm'
  },
  {
    id: 5,
    title: 'The Hindu',
    text: 'https://www.shacklefree.in/https://www.thehindu.com/entertainment/movies/citadel-series-review-priyanka-chopra-jonas-richard-maddens-spy-game-is-too-template-for-its-own-good/article66788719.ece',
    state: 'Works like a charm'

  },
  {
    id: 6,
    title: 'Many more..',

    state: 'Works like a charm'
  }
  
];

  return (

    <Container maxW={'6xl'} mt={10}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
        
        {myArray.map((feature) => (
          <HStack key={feature.id} align={'top'}>
            <Box color={'#EF5D60'} px={1}>
              <Icon as={CheckIcon} />
            </Box>
            <Link href={feature.text}>
            <VStack align={'start'}>
              <Text fontWeight={800} color='#'>{feature.title}</Text>
            </VStack>
            </Link>
          </HStack>
        ))}
        
      </SimpleGrid>
    </Container>

  );
};

export default SupportedSites;
