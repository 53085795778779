import { React, useEffect, useState } from "react";
import {
  useLocation
} from "react-router-dom"
import {
  Button,
  Stack,
  Text,
  Card,
  Image,
  Heading,
  Divider,
  CardFooter,
  ButtonGroup,
  CardBody,
  Container,
  Flex

} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import parse from 'html-react-parser'
const Images = () => {
  const { state} = useLocation();
  const[albums, setAlbums] = useState([]);

  useEffect(() => {
    console.log(state.token)
    fetch('https://photoslibrary.googleapis.com/v1/albums?pageSize=50', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' +state.token,
      },
    }).then(response => {
      return response.json()
    } ).then(response => setAlbums(response.albums))
  }, [])

  return (
    <div>
            <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
      { albums.map( album => (
<Card maxW='sm'>
  <CardBody>
    <Image
      src={album.coverPhotoBaseUrl}
      alt={album.title}
      borderRadius='lg'
    />
    <Stack mt='6' spacing='3'>
      <Heading size='md'>{album.title}</Heading>

    </Stack>
  </CardBody>
  <Divider />
  <CardFooter>
    <ButtonGroup spacing='2'>
      <Button variant='solid' colorScheme='blue'>
        CleanUp
      </Button>
      <Button variant='ghost' colorScheme='blue'>
        View
      </Button>
    </ButtonGroup>
  </CardFooter>
</Card>))}
</Flex>
</Container>
    </div>
  );
};

export default Images;
