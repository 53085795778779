import { useEffect } from "react";
import {
  useNavigate, useSearchParams
} from "react-router-dom"

const Redirect = (props) => {
  const navigate = useNavigate();
    let token = "";
    var ranonce = false;
      // This function will called only once
  useEffect(() => {
    if (!ranonce) {
    loadDataOnlyOnce();
    }
  }, [])

  const loadDataOnlyOnce = () => {
    ranonce = true;
    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")
    console.log(code)
    var requestOptions = {
        method: 'POST',

      };
      
      fetch("https://oauth2.googleapis.com/token?client_id=24980913017-1uek6sss0q149jb7m4o1fmn1q8l2lbcsb1n.apps.googleusercontent.com&client_secret=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sssX&code="+code+"&grant_type=authorization_code&redirect_uri=http://localhost:3000/auth/google/redirect", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            token = result.access_token
            console.log(token)
            navigate('/tools/images', {state:{token:token}});
        })
        .catch(error => console.log('error', error));
  }

}

export default Redirect;