import {React} from "react";

  import { Progress , Card} from '@chakra-ui/react'
const Loading = () => {

return (

<Progress alignContent={'center'} size='xs' isIndeterminate colorScheme='orange'/>

);
};

export default Loading;
