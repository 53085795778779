import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom"

import SearchResult from './SearchResult';
import Contact from './Contact';
import Home from './Home';
import URL from './URL';
import Tools from './Tools';
import Redirect from './Redirect';
import Stats from './Stats';
import Images from './Images';


function App() {

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/result" element={<SearchResult />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/internal/tools" element={<Tools />} />
          <Route path="/auth/google/redirect" element={<Redirect/>}/>
          <Route path="/internal/stats" element={<Stats/>}/>
          <Route path="/tools/images" element={<Images/>}/>
          <Route
            path="*"
            element={<URL />}
          />
        </Routes>
      </BrowserRouter>

    </ChakraProvider>
  );
}

export default App;
