import {
    Box,
    Flex,
    Heading,
    Stack,
    useColorModeValue,
    VStack,
  } from '@chakra-ui/react';
  import React from 'react';
  
  
  export default function Contact() {
 
  
    return (
      <Flex
        bg={useColorModeValue('gray.100', 'gray.900')}
        align="center"
        justify="center"
     
        id="contact">
        <Box 
          borderRadius="lg"
          m={{ base: 5, md: 16, lg: 10 }}
          p={{ base: 5, lg: 16 }}>
          <Box w='100%' >
            <VStack spacing={{ base: 4, md: 8, lg: 34 }}>
              <Heading
                fontSize={{
                  base: '3xl',
                  md: '3xl',
                }}>
                Something Broken?
              </Heading>
  
              <Stack
                spacing={{ base: 4, md: 8, lg: 28 }}
                direction={{ base: 'column', md: 'row' }}>
       
  
                <Box
                  bg={useColorModeValue('white', 'gray.700')}
                  borderRadius="md"
                  p={10}
                  color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                  shadow="base">
                  <VStack spacing={8}>

  
                    
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd1oMDSQRV9Whby41V-HKuiqMiJHju4SIBO4CbM1vhOWkMezQ/viewform?embedded=true" width="480" height="480" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>        </VStack>
                </Box>
              </Stack>
            </VStack>
          </Box>
        </Box>
      </Flex>
    );
  }