import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { ReactNode } from 'react';
  import { BsPerson } from 'react-icons/bs';
  import { FiServer } from 'react-icons/fi';
  import { GoLocation } from 'react-icons/go';
  import { useState, useEffect,useRef } from 'react';
  import { RichTable } from './components/RichTable';


  
  
  
  interface StatsCardProps {
    title: string;
    stat: string;
    icon: ReactNode;
  }
  function StatsCard(props: StatsCardProps) {

    const { title, stat, icon } = props;
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'}
        shadow={'md'}
        border={''}
        borderColor={useColorModeValue('gray.800', 'gray.500')}
        rounded={'lg'}>
        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              {stat}
            </StatNumber>
          </Box>
          <Box
            my={'auto'}
            color={useColorModeValue('gray.800', 'gray.200')}
            alignContent={'center'}>
            {icon}
          </Box>
        </Flex>
      </Stat>
    );
  }
  
  export default function Stats() {
    const dataFetchedRef = useRef(false);
      // Data for the table to display; can be anything

  
      // This function will called only once
  const[stats, setStats] = useState([])
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    init();
  }, [])
    const init = () => {
    fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/stats', {
      method: 'GET',
      
      headers: {
        'Content-type': 'application/json;',
      },
    }).then(response => {
      if (response.ok) {
   
        return response.json()
      }
      throw new Error('Something went wrong');
    }

    )
      .then(response => {

        setStats(response)

      }).catch((error) => {

      })
    }
    return (
      
      <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>

        <chakra.h2
          textAlign={'center'}
          fontSize={'3xl'}
          py={10}
          fontWeight={'bold'}>
          Quick Stats.
        </chakra.h2>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
         
          <StatsCard
            title={'Unique Urls'}
            stat={stats.length}
            icon={<FiServer size={'3em'} />}
          />
          <StatsCard
            title={'Paywall Removed'}
            stat={stats.filter(urls => urls.count === true).length}
            icon={<GoLocation size={'3em'} />}
          />
           <StatsCard
            title={'Paywall Stays'}
            stat={stats.filter(urls => urls.count !== true).length}
            icon={<GoLocation size={'3em'} />}
          />
        </SimpleGrid>
        <br></br>
        <RichTable data = {stats}></RichTable>


      </Box>
      
    );
  }